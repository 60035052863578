import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  &.double-x-small {
    padding: 0 4px;

    &.icon-only {
      padding: 0;
    }
  }

  &.x-small {
    padding: 2px 8px;

    &.text-only {
      padding: 2px 10px;
    }

    &.icon-only {
      padding: 2px;
    }
  }

  &.small {
    padding: 4px 12px;

    &.text-only {
      padding: 4px 14px;
    }

    &.icon-only {
      padding: 4px;
    }
  }

  &.medium {
    padding: 6px 12px;

    &.text-only {
      padding: 6px 16px;
    }

    &.icon-only {
      padding: 6px;
    }
  }

  &.large {
    padding: 8px 16px;

    &.text-only {
      padding: 8px 20px;
    }

    &.icon-only {
      padding: 8px;
    }
  }

  &.x-large {
    padding: 12px 20px;

    &.text-only {
      padding: 12px 24px;
    }

    &.icon-only {
      padding: 12px;
    }

    .btn-text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &:not(.vertical-padding) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export interface ButtonSizeProps {
  size?: 'double-x-small' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  content?: React.ReactNode;
  verticalPadding?: boolean;
  iconOnly?: boolean;
  textOnly?: boolean;
}

export const ButtonSize = (props: ButtonSizeProps) => {
  const {
    size = 'large',
    content,
    verticalPadding = false,
    iconOnly = false,
    textOnly = false,
  } = props;

  return (
    <Container
      className={cl('button-size', size, {
        'vertical-padding': verticalPadding,
        'icon-only': iconOnly,
        'text-only': textOnly,
      })}
    >
      {content}
    </Container>
  );
};
